import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CabinStickySettings_S as CabinStickySettings,
  ExtraSettings_S as ExtraSettings,
  ExtraStickySettings_S as ExtraStickySettings,
  GeneralStickySettings_S as GeneralStickySettings,
  ImageInfo_S as ImageInfo,
  LocationStickySettings_S as LocationStickySettings,
  ProgressTrackerStep_S as ProgressTrackerStep,
  SiteSettingsResponse,
  BookingJourneySticky_S as BookingJourneyStickySettings,
  Maybe,
  Scalars,
} from "@generated/types";
import {
  BookingJourneyStep,
  ProgressTrackerTypes,
  StepperTag,
} from "../../interfaces/booking";
import { ExtrasStepperTag } from "../../interfaces/extras";
import {
  addTrailingSlashToUrl,
  createPathFromUrl,
  getBookingStepByUrl,
  getJourneyStepByUrl,
  Nullable,
} from "../../utils";
import { filterXSS } from "xss";

export type AdditionalSearchLocationsData = {
  locationIds: string[];
};

export type AdditionalSearchCabinsData = {
  locationId: string;
};
export type AdditionalBookingJourneyData = {
  locations?: AdditionalSearchLocationsData;
  cabins?: AdditionalSearchCabinsData;
  // ...more can be added here
};

export type BasketStickySettings = {
  __typename?: "BasketStickySettings";
  title?: Maybe<Scalars["String"]>;
  basketIcon?: Maybe<ImageInfo>;
  closeIcon?: Maybe<ImageInfo>;
  itemsInBasketText?: Maybe<Scalars["String"]>;
  continueCTAText?: Maybe<Scalars["String"]>;
  bookingPageReference?: Maybe<Scalars["String"]>;
};

export type SiteSettingsState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  siteSettings: Nullable<SiteSettingsResponse>;
  getSiteSettingsRequest?: void;
  fhBookingSteps: Nullable<BookingJourneyStep[]>;
  fhExtrasSteps: Nullable<BookingJourneyStep[]>;
  fhCabinUpgradeSteps: Nullable<BookingJourneyStep[]>;
  payRemainingSteps: Nullable<BookingJourneyStep[]>;
  cabinStickySettings: Nullable<CabinStickySettings>;
  locationStickySettings: Nullable<LocationStickySettings>;
  extraStickySettings: Nullable<ExtraStickySettings>;
  generalStickySettings: Nullable<GeneralStickySettings>;
  basketStickySettings: Nullable<BasketStickySettings>;
  bookingJourneyStickySettings: Nullable<BookingJourneyStickySettings>;
  activeBookingStepIndex: number;
  activeExtrasStepIndex: number;
  activeCabinUpgradesStepIndex: number;
  activePayRemainingStepIndex: number;
  loginPageUrl: Nullable<string>;
  loginNextPageUrl: Nullable<string>;
  homePageIcon: Nullable<ImageInfo>;
  additionalBookingJourneyData: Nullable<AdditionalBookingJourneyData>;
  dueSoonThresholdDays: Nullable<number>;
  extraSettings: Nullable<ExtraSettings>;
  canSkipActiveBookingStep: boolean;
  canSkipActiveExtraStep: boolean;
  canSkipActiveCabinUpgradeStep: boolean;
  progressTrackerSteps: any;
  extrasProgressTrackerSteps: any;
  cabinUpgradesProgressTrackerSteps: any;
  manageBookingsRef: Nullable<string>;
  viewAllBookingsRef: Nullable<string>;
  isStepBeforeExtrasJourneyPayment: boolean;
  isLoggedInOnExtrasPage?: boolean;
  reachedBookingSummaryPage?: boolean;
  deplayInSubscribeModal?: Nullable<number>;
};

const initialState: SiteSettingsState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  siteSettings: null,
  fhBookingSteps: null,
  fhExtrasSteps: null,
  fhCabinUpgradeSteps: null,
  payRemainingSteps: null,
  cabinStickySettings: null,
  locationStickySettings: null,
  extraStickySettings: null,
  generalStickySettings: null,
  basketStickySettings: null,
  bookingJourneyStickySettings: null,
  activeBookingStepIndex: 0,
  activeExtrasStepIndex: 0,
  activeCabinUpgradesStepIndex: 0,
  activePayRemainingStepIndex: 0,
  loginPageUrl: null,
  loginNextPageUrl: null,
  homePageIcon: null,
  additionalBookingJourneyData: null,
  dueSoonThresholdDays: null,
  extraSettings: null,
  canSkipActiveBookingStep: false,
  canSkipActiveExtraStep: false,
  canSkipActiveCabinUpgradeStep: false,
  progressTrackerSteps: null,
  extrasProgressTrackerSteps: null,
  cabinUpgradesProgressTrackerSteps: null,
  manageBookingsRef: null,
  viewAllBookingsRef: null,
  isStepBeforeExtrasJourneyPayment: false,
  isLoggedInOnExtrasPage: false,
  reachedBookingSummaryPage: false,
  deplayInSubscribeModal: null,
});

export const siteSettingsSlice = createSlice({
  name: "siteSettings",
  initialState,
  reducers: {
    getSiteSettingsAction: (state) => {
      state.loading = true;
    },
    getSiteSettingsSuccessAction: (
      state,
      action: PayloadAction<Required<SiteSettingsResponse>>,
    ) => {
      const progressTrackerSteps =
        (action.payload.progressTrackers?.bookingJourneyProgressTracker?.map(
          (step) => {
            step.url = addTrailingSlashToUrl(step?.url);
            return step;
          },
        ) || []) as ProgressTrackerStep[];

      const extrasProgressTrackerSteps =
        (action.payload.progressTrackers?.extrasJourneyProgressTracker?.map(
          (step) => {
            step.url = addTrailingSlashToUrl(step?.url);
            return step;
          },
        ) || []) as ProgressTrackerStep[];

      const cabinUpgradesProgressTrackerSteps =
        (action.payload.progressTrackers?.cabinUpgradesJourneyTracker?.map(
          (step) => {
            step.url = addTrailingSlashToUrl(step?.url);
            return step;
          },
        ) || []) as ProgressTrackerStep[];

      const payRemainingProgressTrackerSteps =
        (action.payload.progressTrackers?.payRemainingJourneyProgressTracker?.map(
          (step) => {
            step.url = addTrailingSlashToUrl(step?.url);
            return step;
          },
        ) || []) as ProgressTrackerStep[];

      const currentBookingSteps = state.fhBookingSteps?.map((step) => {
        step.url = addTrailingSlashToUrl(step?.url);
        return step;
      });

      const currentExtrasSteps = state.fhExtrasSteps?.map((step) => {
        step.url = addTrailingSlashToUrl(step?.url);
        return step;
      });

      const currentCabinUpgradesSteps = state.fhCabinUpgradeSteps?.map(
        (step) => {
          step.url = addTrailingSlashToUrl(step?.url);
          return step;
        },
      );

      const currentPayRemainSteps = state.payRemainingSteps?.map((step) => {
        step.url = addTrailingSlashToUrl(step?.url);
        return step;
      });

      const bookingJourneySteps = getStepperSteps(
        progressTrackerSteps,
        currentBookingSteps,
      );

      const extrasJourneySteps = getStepperSteps(
        extrasProgressTrackerSteps,
        currentExtrasSteps,
      );

      const cabinUpgradesJourneySteps = getStepperSteps(
        cabinUpgradesProgressTrackerSteps,
        currentCabinUpgradesSteps,
        state.activeCabinUpgradesStepIndex,
      );

      const payRemainingJourneySteps = getStepperSteps(
        payRemainingProgressTrackerSteps,
        currentPayRemainSteps,
      );

      return {
        ...initialState,
        siteSettings: action.payload,
        fhBookingSteps: bookingJourneySteps,
        fhExtrasSteps: extrasJourneySteps,
        fhCabinUpgradeSteps: cabinUpgradesJourneySteps,
        payRemainingSteps: payRemainingJourneySteps,
        bookingJourneyStickySettings:
          action.payload.bookingJourneyStickySettings,
        cabinStickySettings: action.payload.cabinStickySettings,
        locationStickySettings: action.payload.locationStickySettings,
        extraStickySettings: action.payload.extraStickySettings,
        generalStickySettings: action.payload.generalStickySettings,
        activeBookingStepIndex: state.activeBookingStepIndex,
        loginPageUrl: createPathFromUrl(action.payload.loginPageUrl as string),
        loginNextPageUrl: createPathFromUrl(
          action.payload.loginNextPageUrl as string,
        ),
        homePageIcon: action.payload.homePageIcon,
        loaded: true,
        loading: false,
        dueSoonThresholdDays: action.payload.dueSoonThresholdDays,
        extraSettings: action.payload.extraSettings,
        progressTrackerSteps: progressTrackerSteps,
        extrasProgressTrackerSteps: extrasProgressTrackerSteps,
        cabinUpgradesProgressTrackerSteps: cabinUpgradesProgressTrackerSteps,
        manageBookingsRef: filterXSS(action.payload.manageBookingsRef),
        viewAllBookingsRef: filterXSS(action.payload.viewAllBookingsRef),
        isLoggedInOnExtrasPage: state.isLoggedInOnExtrasPage,
        reachedBookingSummaryPage: state.reachedBookingSummaryPage,
        deplayInSubscribeModal: state.deplayInSubscribeModal,
        activeCabinUpgradesStepIndex: state.activeCabinUpgradesStepIndex,
        activeExtrasStepIndex: state.activeExtrasStepIndex,
        isStepBeforeExtrasJourneyPayment:
          state.isStepBeforeExtrasJourneyPayment,
      };
    },
    getSiteSettingsFailAction: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.loading = false;
    },
    setActiveBookingStepByUrlAction: (state, action: PayloadAction<string>) => {
      const steps = state.fhBookingSteps;
      const url = action.payload;
      const index =
        steps?.findIndex(
          (step) => step?.url === url || step?.url?.includes(url),
        ) ?? 0;
      const stepFoundFromUrl = (steps || [])[index];

      if (stepFoundFromUrl?.tag === StepperTag.cabins) {
        const locationStep = steps?.find(
          (step) => step.tag === StepperTag.locations,
        );
        if (locationStep && !locationStep?.completed) {
          locationStep.completed = true;
        }
      }

      state.activeBookingStepIndex = index;
    },
    setActiveExtrasStepByUrlAction: (state, action: PayloadAction<string>) => {
      const steps = state.fhExtrasSteps;
      const url = action.payload;
      const index =
        steps?.findIndex(
          (step) => step?.url === url || step?.url?.includes(url),
        ) ?? 0;
      const paymentStepIndex = steps?.findIndex(
        (step) => step.tag === ExtrasStepperTag.payment,
      );
      state.isStepBeforeExtrasJourneyPayment = index === paymentStepIndex - 1;
      state.activeExtrasStepIndex = index;
    },
    setActiveCabinUpgradeStepByUrlAction: (
      state,
      action: PayloadAction<string>,
    ) => {
      const steps = state.fhCabinUpgradeSteps;
      const url = action.payload;
      const index =
        steps?.findIndex(
          (step) => step?.url === url || step?.url?.includes(url),
        ) ?? 0;
      const paymentStepIndex = steps?.findIndex(
        (step) => step.tag === ExtrasStepperTag.payment,
      );
      state.isStepBeforeExtrasJourneyPayment = index === paymentStepIndex - 1;
      state.activeCabinUpgradesStepIndex = index;
    },
    setIsLoggedInOnExtrasPage: (state, action: PayloadAction<boolean>) => {
      state.isLoggedInOnExtrasPage = action.payload;
    },
    resetIsLoggedInOnExtrasPage: (state, action) => {
      state.isLoggedInOnExtrasPage = false;
    },
    setActivePayRemainStepByUrlAction: (
      state,
      action: PayloadAction<string>,
    ) => {
      const url = action.payload;
      const steps = state.payRemainingSteps;
      const index =
        steps?.findIndex(
          (step) => step?.url === url || step?.url?.includes(url),
        ) ?? 0;

      state.activePayRemainingStepIndex = index;
    },
    confirmActiveBookingStepAction: (state) => {
      const fhBookingSteps = state.fhBookingSteps;
      if (fhBookingSteps !== null) {
        const activeBookingStep = fhBookingSteps[state.activeBookingStepIndex];
        activeBookingStep.completed = true;
      }

      state.fhBookingSteps = fhBookingSteps;
    },
    confirmActiveExtrasStepAction: (state) => {
      const steps = state.fhExtrasSteps;

      if (steps !== null) {
        const activeExtrasStep = steps[state.activeExtrasStepIndex];
        if (activeExtrasStep) {
          activeExtrasStep.completed = true;
        }
      }

      state.fhExtrasSteps = steps;
    },
    confirmActiveCabinUpgradesStepAction: (state) => {
      const fhCabinUpgradeSteps = state.fhCabinUpgradeSteps;

      if (fhCabinUpgradeSteps !== null) {
        const activeCabinUpgradesStep =
          fhCabinUpgradeSteps[state.activeCabinUpgradesStepIndex];
        if (activeCabinUpgradesStep) {
          activeCabinUpgradesStep.completed = true;
        }
      }

      state.fhCabinUpgradeSteps = fhCabinUpgradeSteps;
    },
    confirmActivePayRemainStepAction: (state) => {
      const payRemainingSteps = state.payRemainingSteps;

      if (payRemainingSteps !== null) {
        const activePayRemainStep =
          payRemainingSteps[state.activePayRemainingStepIndex];
        if (activePayRemainStep) {
          activePayRemainStep.completed = true;
        }
      }

      state.payRemainingSteps = payRemainingSteps;
    },
    setBookingStepsAdditionalData: (
      state,
      action: PayloadAction<AdditionalBookingJourneyData>,
    ) => {
      const fhBookingSteps = state.fhBookingSteps;
      let additionalBookingJourneyData: Nullable<AdditionalBookingJourneyData> =
        state.additionalBookingJourneyData;
      if (fhBookingSteps) {
        const activeBookingStep = fhBookingSteps[state.activeBookingStepIndex];
        if (action.payload) {
          switch (activeBookingStep?.tag) {
            case StepperTag.locations:
              additionalBookingJourneyData = {
                ...additionalBookingJourneyData,
                locations: action.payload as AdditionalSearchLocationsData,
              };
              break;
            case StepperTag.cabins:
              additionalBookingJourneyData = {
                ...additionalBookingJourneyData,
                cabins: action.payload as AdditionalSearchCabinsData,
              };
              break;
          }
        }
      }

      state.additionalBookingJourneyData = additionalBookingJourneyData;
    },
    setBookingStepsToUncompleted: (state) => {
      state.fhBookingSteps =
        state.fhBookingSteps?.map((step) => {
          step.completed = false;
          return step;
        }) || state.fhBookingSteps;
    },
    setExtrasStepsToUncompleted: (state) => {
      state.fhExtrasSteps =
        state.fhExtrasSteps?.map((step) => {
          step.completed = false;
          return step;
        }) || state.fhExtrasSteps;
    },
    setCabinUpgradeStepsToUncompleted: (state) => {
      state.fhCabinUpgradeSteps =
        state.fhCabinUpgradeSteps?.map((step) => {
          step.completed = false;
          return step;
        }) || state.fhCabinUpgradeSteps;
    },
    setSkippableBookingStepAction: (state) => {
      const fhBookingSteps = state.fhBookingSteps;
      const progressTrackerSteps = state.progressTrackerSteps;

      const isSkippableBookingStep = () => {
        const currentBookingStep = getBookingStepByUrl(fhBookingSteps);
        const findSkippableStep =
          progressTrackerSteps && progressTrackerSteps.length > 0
            ? progressTrackerSteps.find(
                (step: ProgressTrackerTypes) =>
                  step?.url === currentBookingStep?.url,
              )
            : undefined;
        const isSkippableStep =
          !!findSkippableStep?.skipStepCTALabel?.length &&
          !!findSkippableStep?.skipStepMessage?.length;

        return isSkippableStep;
      };

      state.canSkipActiveBookingStep = isSkippableBookingStep();
    },
    setSkippableExtrasStepAction: (state) => {
      const fhExtrasSteps = state.fhExtrasSteps;
      const extrasProgressTrackerSteps = state.extrasProgressTrackerSteps;

      const isSkippableExtraStep = () => {
        const currentExtraStep = getJourneyStepByUrl(fhExtrasSteps);
        const findSkippableStep =
          extrasProgressTrackerSteps && extrasProgressTrackerSteps.length > 0
            ? extrasProgressTrackerSteps.find(
                (step: ProgressTrackerTypes) =>
                  step?.url === currentExtraStep?.url,
              )
            : undefined;

        const isSkippableStep =
          !!findSkippableStep?.skipStepCTALabel?.length &&
          !!findSkippableStep?.skipStepMessage?.length;

        return isSkippableStep;
      };

      state.canSkipActiveExtraStep = isSkippableExtraStep();
    },
    setSkippableCabinUpgradeStepAction: (state) => {
      const fhCabinUpgradesSteps = state.fhCabinUpgradeSteps;
      const cabinUpgadeProgressTrackerSteps =
        state.cabinUpgradesProgressTrackerSteps;

      const isSkippableCabinUpgradeStep = () => {
        const currentCabinUpgradeStep =
          getJourneyStepByUrl(fhCabinUpgradesSteps);
        const findSkippableStep =
          cabinUpgadeProgressTrackerSteps &&
          cabinUpgadeProgressTrackerSteps.length > 0
            ? cabinUpgadeProgressTrackerSteps.find(
                (step: ProgressTrackerTypes) =>
                  step?.url === currentCabinUpgradeStep?.url,
              )
            : undefined;

        const isSkippableStep =
          !!findSkippableStep?.skipStepCTALabel?.length &&
          !!findSkippableStep?.skipStepMessage?.length;

        return isSkippableStep;
      };

      state.canSkipActiveCabinUpgradeStep = isSkippableCabinUpgradeStep();
    },
    setReachedBookingSummaryPage: (state, action: PayloadAction<boolean>) => {
      state.reachedBookingSummaryPage = action.payload;
    },
  },
});

const getStepperSteps = (
  progressTrackerSteps: ProgressTrackerStep[],
  currentSteps: Nullable<BookingJourneyStep[]>,
  confirmStepsBeforeThisIndex?: number,
): BookingJourneyStep[] => {
  const progressTrackerStepsWithoutNulls: ProgressTrackerStep[] =
    progressTrackerSteps.filter((step) => step !== null && step.title !== null);

  return progressTrackerStepsWithoutNulls.map(
    (step, i) =>
      ({
        label: step.title,
        url: createPathFromUrl(step?.url as string),
        tag: step.tag?.replace(" ", "").trim() as string,
        disableClick: step.tag === "login",
        completed:
          !!confirmStepsBeforeThisIndex && i < confirmStepsBeforeThisIndex
            ? true
            : currentSteps?.find((s) => s.tag === step.tag)?.completed || false,
      }) as BookingJourneyStep,
  );
};

export const {
  getSiteSettingsAction,
  getSiteSettingsSuccessAction,
  getSiteSettingsFailAction,
  setActiveBookingStepByUrlAction,
  setActiveExtrasStepByUrlAction,
  setActivePayRemainStepByUrlAction,
  confirmActiveBookingStepAction,
  confirmActiveExtrasStepAction,
  confirmActivePayRemainStepAction,
  setBookingStepsAdditionalData,
  setBookingStepsToUncompleted,
  setExtrasStepsToUncompleted,
  setSkippableBookingStepAction,
  setSkippableExtrasStepAction,
  setIsLoggedInOnExtrasPage,
  resetIsLoggedInOnExtrasPage,
  setReachedBookingSummaryPage,
  setActiveCabinUpgradeStepByUrlAction,
  confirmActiveCabinUpgradesStepAction,
  setSkippableCabinUpgradeStepAction,
  setCabinUpgradeStepsToUncompleted,
} = siteSettingsSlice.actions;

export default siteSettingsSlice.reducer;
