import { useSelector } from "react-redux";
import { BookingJourneyStep, StepperTag } from "../../interfaces/booking";
import { ExtrasStepperTag } from "../../interfaces/extras";
import {
  getBookingStepByTag,
  getExtrasOrUpgradesJourneyStepByTag,
} from "../../utils";
import { RootState } from "../slices";
import { filterXSS } from "xss";

export const siteSettingsSelector = (state: RootState) => state.siteSettings;
export const activeStepIndexSelector = (state: RootState) =>
  state.siteSettings.activeBookingStepIndex;
export const fhBookingStepsSelector = (state: RootState) =>
  state.siteSettings.fhBookingSteps;
export const activeStepSelector = (state: RootState) =>
  (state.siteSettings.fhBookingSteps || [])[
    state.siteSettings.activeBookingStepIndex
  ] || null;
export const previousFromActiveStepSelector = (
  state: RootState,
  index: number = state.siteSettings.activeBookingStepIndex,
): BookingJourneyStep => {
  const previousStep =
    (state.siteSettings.fhBookingSteps || [])[index - 1] || null;
  if (previousStep?.tag === StepperTag.login) {
    // if previous step is login, then return the step before.
    return previousFromActiveStepSelector(state, index - 1);
  }
  return previousStep;
};
export const nextFromActiveStepSelector = (state: RootState) =>
  (state.siteSettings.fhBookingSteps || [])[
    state.siteSettings.activeBookingStepIndex + 1
  ] || null;
export const siteSettingsLoadedSelector = (state: RootState) =>
  state.siteSettings.loaded;
export const basketStickySettingsSelector = (state: RootState) =>
  state.siteSettings.basketStickySettings;
export const locationStickySettingsSelector = (state: RootState) =>
  state.siteSettings.locationStickySettings;
export const cabinStickySettingsSelector = (state: RootState) =>
  state.siteSettings.cabinStickySettings;
export const bookingJourneyStickySettingsSelector = (state: RootState) =>
  state.siteSettings.bookingJourneyStickySettings;

export const generalStickySettingsSelector = (state: RootState) =>
  state.siteSettings.generalStickySettings;

export const extraStickySettingsSelector = (state: RootState) =>
  state.siteSettings.extraStickySettings;

export const additionalBookingJourneyDataSelector = (state: RootState) =>
  state.siteSettings.additionalBookingJourneyData;
export const siteSettingsDueSoonDaysSelector = (state: RootState) =>
  state.siteSettings.dueSoonThresholdDays;
export const loginPageUrlSelector = (state: RootState) =>
  filterXSS(state.siteSettings.loginPageUrl);

export const nextFromActiveStepExtrasSelector = (state: RootState) =>
  (state.siteSettings.fhExtrasSteps || [])[
    state.siteSettings.activeExtrasStepIndex + 1
  ] || null;

export const useSiteSettingsSelector = () => useSelector(siteSettingsSelector);

export const useActiveStepSelector = () => useSelector(activeStepSelector);
export const usePreviousFromActiveStepSelector = () =>
  useSelector(previousFromActiveStepSelector);
export const useNextFromActiveStepSelector = () =>
  useSelector(nextFromActiveStepSelector);
export const useNextFromActiveStepExtrasSelector = () =>
  useSelector(nextFromActiveStepExtrasSelector);
export const useSiteSettingsLoadedSelector = () =>
  useSelector(siteSettingsLoadedSelector);
export const useAdditionalBookingJourneyDataSelector = () =>
  useSelector(additionalBookingJourneyDataSelector);
export const useLoginPageUrlSelector = () => useSelector(loginPageUrlSelector);

export const useFindStepSelector = (tag: StepperTag) => {
  return getBookingStepByTag(tag, useSelector(fhBookingStepsSelector));
};
export const useSiteSettingsDueSoonDaysSelector = () =>
  useSelector(siteSettingsDueSoonDaysSelector);
export const useBasketStickySettingsSelector = () =>
  useSelector(basketStickySettingsSelector);

// Extras Stepper
export const fhExtrasStepsSelector = (state: RootState) =>
  state.siteSettings.fhExtrasSteps;
export const activeExtrasStepSelector = (state: RootState) =>
  (state.siteSettings.fhExtrasSteps || [])[
    state.siteSettings.activeExtrasStepIndex
  ] || null;
export const previousExtrasFromActiveStepSelector = (
  state: RootState,
  index: number = state.siteSettings.activeExtrasStepIndex,
): BookingJourneyStep => {
  return (state.siteSettings.fhExtrasSteps || [])[index - 1] || null;
};
export const nextExtrasFromActiveStepSelector = (state: RootState) =>
  (state.siteSettings.fhExtrasSteps || [])[
    state.siteSettings.activeExtrasStepIndex + 1
  ] || null;

export const useExtrasActiveStepSelector = () =>
  useSelector(activeExtrasStepSelector);
export const usePreviousExtrasFromActiveStepSelector = () =>
  useSelector(previousExtrasFromActiveStepSelector);
export const useNextExtrasFromActiveStepSelector = () =>
  useSelector(nextExtrasFromActiveStepSelector);
export const useFindExtrasStepSelector = (tag: ExtrasStepperTag) =>
  getExtrasOrUpgradesJourneyStepByTag(tag, useSelector(fhExtrasStepsSelector));

// Cabin Upgrades Stepper
export const fhCabinUpgradeStepsSelector = (state: RootState) =>
  state.siteSettings.fhCabinUpgradeSteps;
export const activeCabinUpgradeStepSelector = (state: RootState) =>
  (state.siteSettings.fhCabinUpgradeSteps || [])[
    state.siteSettings.activeCabinUpgradesStepIndex
  ] || null;
export const previousCabinUpgradesFromActiveStepSelector = (
  state: RootState,
  index: number = state.siteSettings.activeCabinUpgradesStepIndex,
): BookingJourneyStep => {
  return (state.siteSettings.fhCabinUpgradeSteps || [])[index - 1] || null;
};
export const nextCabinUpgradesFromActiveStepSelector = (state: RootState) =>
  (state.siteSettings.fhCabinUpgradeSteps || [])[
    state.siteSettings.activeCabinUpgradesStepIndex + 1
  ] || null;

export const useCabinUpgradeActiveStepSelector = () =>
  useSelector(activeCabinUpgradeStepSelector);
export const usePreviousCabinUpgradesFromActiveStepSelector = () =>
  useSelector(previousCabinUpgradesFromActiveStepSelector);
export const useNextCabinUpgradesFromActiveStepSelector = () =>
  useSelector(nextCabinUpgradesFromActiveStepSelector);
export const useFindCabinUpgradesStepSelector = (tag: ExtrasStepperTag) =>
  getExtrasOrUpgradesJourneyStepByTag(
    tag,
    useSelector(fhCabinUpgradeStepsSelector),
  );
