import "../styles/globals.css";

import { useEffect } from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import createEmotionCache from "../src/createEmotionCache";
import { Provider } from "react-redux";
import theme from "../src/themes/SemanticTheme";
import { CookiesProvider } from "react-cookie";
import { store } from "../src/store";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "../src/apollo-client";
import ErrorBoundary from "src/ErrorBoundary";
import { MouseMovement } from "src/experiments/components/MouseMovement";
import { DeveloperTools } from "src/experiments/components/DeveloperTools";
import { ExperimentData } from "src/experiments/components/ExperimentData";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const App = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useEffect(() => {
    MouseMovement();
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Provider store={store}>
          <CookiesProvider>
            <CacheProvider value={emotionCache}>
              <Head>
                <meta
                  name="viewport"
                  content="initial-scale=1, width=device-width"
                />
              </Head>
              <ApolloProvider client={apolloClient}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Component {...pageProps} />
                </ThemeProvider>
              </ApolloProvider>
            </CacheProvider>
          </CookiesProvider>
          {props?.pageProps?.developerTools && <DeveloperTools />}
          <ExperimentData />
        </Provider>
      </ErrorBoundary>
    </>
  );
};

export default App;
